import React from 'react';

import config from '../../config';

export default function Footer() {
  return (
    <header id="header">
      <h1>{config.heading}</h1>
      <p>
        An African based software company-{' '}
        <a href="mailto:developer@rosoftware.ro">keeniletechlabs@gmail.com</a>
        <br />
      </p>
    </header>
  );
}
