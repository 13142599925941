import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SlideShow from '../components/SlideShow';
import bg01 from '../assets/images/bg01.jpeg';
import bg02 from '../assets/images/bg02.jpg';
import bg03 from '../assets/images/bg03.jpeg';

const IndexPage = () => {
  const settings = {
    images: [
      { url: bg01, position: 'center' },
      { url: bg02, position: 'center' },
      { url: bg03, position: 'center' },
    ],
    delay: 6000,
  };

  return (
    <Layout>
      <Header />
      <Footer />
      <SlideShow settings={settings} />
    </Layout>
  );
};

export default IndexPage;
